import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import { css } from "@emotion/react"
import TopNav from "../../TopNav"
import NewFooter from "../../NewFooter"
import NavBarFramer from "../NavBarFramer/NavBarFramer";

const CurtainStyles = css`
    .color-1,
    .color-2,
    .color-3 {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        transform-origin: top;
        will-change: transform;
    }

    .color-1 {
        z-index: 999;
        background-color: #0038b8;
    }

    .color-2 {
        z-index: 998;
        background-color: #0032a6;
    }

    .color-3 {
        z-index: 997;
        background-color: #002781;
    }
`

const curtainVariant = {
  enter: {
    transition: {
      staggerChildren: 0.08,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
}

const colorVariant = {
  start: { scaleY: 1 },
  enter: {
    scaleY: 0,
    transition: {
      duration: 0.8,
      ease: "easeInOut",
    },
  },
  end: {
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
}

const TransitionCurtain = ({ children, location }) => {
  return (
    <>
      <TopNav />

      <AnimatePresence mode="wait">
        <motion.div
          initial="start"
          animate="enter"
          exit="end"
          variants={curtainVariant}
          css={CurtainStyles}
        >
          {children && children}
          <motion.div variants={colorVariant} className="color-1" />
          <motion.div variants={colorVariant} className="color-2" />
          <motion.div variants={colorVariant} className="color-3" />
        </motion.div>
      </AnimatePresence>

      <NewFooter />
    </>
  )
}

export default TransitionCurtain